import { get, post } from './http'
/*
 *  详细传参请参考  https://www.showdoc.cc/412519195359368?page_id=2494367940924115
 *
 * */

 /************************登入*********************************/
 /**
  *登入
*/


export const ajax = {
   /**
    *App更新接口
    */
    appversioninfo : params => get('/api/auth/appversioninfo',params),
    
    /**
    *发送验证码
    */
    sendMsg:params => post('/api/auth/sendMsg',params),
   
    // 注册 
    register:params => post('/api/auth/register',params),

    // 验证码 登录 
    login:params => post('/api/auth/login',params),


    // 密码登录 
    pwdlogin:params => post('/api/auth/pwdlogin',params),


    // 忘记密码检测验证码  
    checkUser:params => post('/api/auth/checkUser',params),

    // 忘记密码 - 重置密码 
    resetPassword:params => post('/api/auth/resetPassword',params),

    
    // 公告列表 
    announcement:params => post('/api/user/announcement',params),

    // 商品分类列表 
    cateproduct:params => post('/api/sales/cateproduct',params),

    // 商品分类列表  test 
    cateproductnop:params => post('/api/sales/cateproduct-nop',params),

    // 热门竞拍商品列表 
    hotlist:params => post('/api/sales/hotlist',params),

    // 4.1 我的钱包包含泰铢汇率 
    walletmy:params => post('/api/wallet/my',params),

    // 2.1用户信息
    dashboard:params => post('/api/user/dashboard',params),

    // 1.4 退出登录 
    logout:params => post('/api/user/logout',params),

    // 9.2 获取需求单列表 
    helplist:params => post('/api/user/helplist',params),

     //9.1提交需求单 
     help:params => post('/api/user/help',params),
    
    //9.3 获取需求单聊天记录
    supportchat:params => post('/api/user/supportchat',params),

    //9.4 需求单保存聊天记录
    supportchatsumit:params => post('/api/user/supportchatsumit',params),

    //7.4修改用户普通个人信息 
    updateuser:params => post('/api/user/updateuser',params),

    //7.4修改用户邮箱
    updateemail:params => post('/api/user/update-email',params),

    // 7.6 用户没设置过邮箱，第一次设置邮箱
    setEmail:params => post('/api/user/setEmail',params),
    

    //7.5 上传用户头像
    uploaduserhead:(params) => post('/api/user/uploaduserhead',params),

    
    //7.2 修改用户手机号码
    updatephone:(params) => post('/api/user/update-phone',params),

    //2.4 重置用户登录密码
    edituserpassword:(params) => post('/api/user/edituserpassword',params),
    //2.4 重置用户登录密码
    edituserpin:(params) => post('/api/user/edituserpin',params),




    //2.4 获取收货地址 列表 
    addrlist:(params) => post('/api/user/addr-list',params),

    // 11.2 添加收货地址
    addnewaddr:(params) => post('/api/user/add-addr',params),

    // 11.2 添加收货地址
    deladdr:(params) => post('/api/user/del-addr',params),

    // 11.3 修改收货地址
    updateaddr:(params) => post('/api/user/update-addr',params),

    // 12.1提交KYC实名认证审核
    accoutkyc:(params,headers) => post('/api/user/accoutkyc',params,headers),

    // 12.2 KYC实名认证审核详情
    showkyc:(params) => post('/api/user/showkyc',params),

    // pv 列表 
    pvlist:(params) => post('/api/user/pv-list',params),  

    // 10.3 商品详情
    productdetail:(params) => post('/api/sales/productdetail',params),  


    // 1.13 邮箱发送验证码
    sendemail:(params) => post('/api/auth/sendemail',params),  

    // 3.4 我的竞拍 明细 列表
    graphistorylist:(params) => post('/api/purchase/graphistorylist',params),  

    // 3.3 Time Twister 查询产品获奖用户信息
    checkTimetwisterWinner:(params) => post('/api/purchase/check-timetwister-winner',params),  


    // 3.3 Time Twister 查询产品获奖用户信息
    checkCloundbidWinner:(params) => post('/api/purchase/check-cloundbid-winner',params),  


    // 3.1 ONE CLOUND 用户竞拍
    getcloundone:(params) => post('/api/purchase/getcloundone',params), 

    // 3.2 Time Twister 用户竞拍
    graptimetwister:(params) => post('/api/purchase/graptimetwister',params),   

    //16.3 体验金竞拍 ONE CLOUND 用户竞拍
    getcloundonetry:(params) => post('/api/tryorder/getcloundone',params), 
    
    //16.2 体验金竞拍 Time Twister 用户竞拍
    graptimetwistertry:(params) => post('/api/tryorder/graptimetwister',params), 

    // 3.5 商品竞拍晒单区和参与明细
    shaidanJoinlist:(params) => post('/api/user/shaidan-joinlist',params),  

    // 3.8 揭晓 明细 列表
    winningHistory:(params) => post('/api/user/winning-history',params),  
     
    // 3.9 晒单专区
    shaidanist:(params) => post('/api/user/shaidanist',params),

    // 3.6 晒单提交
    submitsaidan:(params) => post('/api/user/submitsaidan',params),  

    // 3.44 兑换列表
    thexlist:(params) => post('/api/purchase/thexlist',params),  

    
    // 3.45 泰铢兑换BIT TOKEN
    thexchangebt:(params) => post('/api/purchase/thexchangebt',params),  



    // 签到
    // 13.2 获取签到日历
    calendarList:(params) => post('/api/user/calendar-list',params),  

    // 13.1 日历签到
    calendarSignup:(params) => post('/api/user/calendar-signup',params),

    // 13.1 日历补签
    signupwithMoney:(params) => post('/api/user/calendar-signupwithMoney',params),
    
    // 钱包
    // 5.3 PV或者Bid Token或者泰铢 流水明细
    walletlog:(params) => post('/api/user/btwalletlog',params),

    // 2.6 泰铢实时价格
    taiprice:(params) => post('/api/user/taiprice',params),

    // 6.1 泰铢提现申请
    wtai:(params) => post('/api/withdraw/wtai',params),

    //  我的卡卷列表
    buyticketList:(params) => post('/api/user/buyticket-list',params),

    // 15.1生活超市列表
    livingList:(params) => post('/api/user/living-list',params),

    // 15.2 生活超市卡卷兑换
    buyTicket:(params) => post('/api/user/buy-ticket',params),


    // 14.1 我的团队
    treeview:(params) => post('/api/user/treeview',params),

    // 11.8添加收货银行卡
    addbank:(params) => post('/api/user/addbank',params),

    // 11.6获取银行列表
    banklist:(params) => post('/api/user/banklist',params),

    // 11.7 银行卡修改
    updatebank:(params) => post('/api/user/updatebank',params),

    // 11.7 银行卡删除
    delbank:(params) => post('/api/user/delbank',params),

    // 16.4 银行分类列表
    getBankCategoryList:(params) => post('/api/bank/getBankCategoryList',params),

    // 5.1 用户提交充值
    submitRecharge:(params) => post('/api/wallet/submit-recharge',params),

    // 5.2 用户提交泰铢提现
    submitWithdraw:(params) => post('/api/wallet/submit-withdraw',params),

    // 5.3 转账
    thaitransfer:(params) => post('/api/wallet/thaitransfer',params),



    // 3.49 首页滚动中奖人信息
    winningnotify:(params) => post('/api/user/winningnotify',params),


    // 3.48 中奖订单回收
    sellwinning:(params) => post('/api/user/sellwinning',params),

    // 7.8 重置二级密码先验证验证码
    verifyresetpincode:(params) => post('/api/user/verifyresetpincode',params),

    // 7.7重置用户二级密码
    resetpin:(params) => post('/api/user/resetpin',params),

    // 3.47 我的抽奖码
    luckynumlist:(params) => post('/api/user/luckynumlist',params),

    // 3.46 我的荣誉榜
    honorlist:(params) => post('/api/user/honorlist',params),


    //11.9 获奖订单邮寄奖品
    winningordermail:(params) => post('/api/user/winningordermail',params),

    
    //11.9 获奖订单邮寄奖品
    bonuspvlog:(params) => post('/api/user/bonuspvlog',params),


    // 3.49 Time Twister中奖订单用户支付   回收或者邮寄之前要先支付
    payttwinningorder:(params) => post('/api/purchase/pay-ttwinningorder',params),


    // 3.6 晒单点赞
    shaidanZan:(params) => post('/api/user/shaidan-zan',params),

    // 16.1 查看中奖信息 
    winnerOrderDetail:(params) => post('/api/order/winnerOrderDetail',params),


    // 1.9 超管登陆用户页面：hash换token
    upd2sdfgateUs345dfserIsdgnfo:(params) => post('/api/sy23t4yut4nsdfc/upd2sdfgateUs345dfserIsdgnfo',params),

}







 