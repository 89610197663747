import Vue from "vue";

// 滚动条
import PerfectScrollbar from 'perfect-scrollbar'
import 'perfect-scrollbar/css/perfect-scrollbar.css'

const elScrollBar = (el) => {
  if (el._ps_ instanceof PerfectScrollbar) {
    el._ps_.update()
  } else {
    el._ps_ = new PerfectScrollbar(el, { suppressScrollX: true })
  }
}

Vue.directive('scrollBar', {
  inserted (el, binding, vnode) {
    const rules = ['fixed', 'absolute', 'relative']
    if (!rules.includes(window.getComputedStyle(el, null).position)) {
      console.error(`perfect-scrollbar所在的容器的position属性必须是以下之一：${rules.join('、')}`)
    }
    elScrollBar(el)
  },
  componentUpdated (el, binding, vnode, oldVnode) {
    try {
      vnode.context.$nextTick(
        () => {
          elScrollBar(el)
        }
      )
    } catch (error) {
      console.error(error)
      elScrollBar(el)
    }
  }
})


import moment from "moment";

Vue.filter("dateFormat",(value,format)=>{
    if(!value) return value;
    format = format || "DD-MM-YYYY";
    return moment(value).format(format)
})

Vue.filter("number_format",(value,max,min)=>{
  if(!value) return value;  
  max = max || 2;
  min = min || 2
  return parseFloat(value).toLocaleString('en', {minimumFractionDigits: max,maximumFractionDigits:min});
})

Vue.filter("int_format",(value)=>{
  if(!value) return value;  
  // max = max || 2;
  // min = min || 2;
  return parseFloat(value).toLocaleString();
})

Vue.filter("tonumber",(value)=>{
  if(!value) return value;  
  // max = max || 2;
  // min = min || 2;
  return parseFloat(value).toLocaleString();
})



Vue.filter("dateMat",(value,format)=>{
  if(!value) return value;
  return moment(value).format(format)
})

Vue.filter("ten",(value)=>{
  if(!value) return value;
  if(value=="0"){
    return '00'
  }else{
    return value < 10 ? '0'+value :value
  }
})

Vue.filter("langtext",(value)=>{
  // if(!value) return value;
  switch(value){
    case "en":
    return "EN"
    break;

    case "cn":
    return "zh-CN"
    break;
    default:
    return "zh-CN"
    break;
  }
})


Vue.filter("langtext",(value)=>{
  switch(value){
    case "en":
    return "English"
    break;

    case "cn":
    return "中文"
    break;

    case "th":
    return "ไทย"
    break;

    default:
    return "中文"
    break;
  }
})




Vue.filter("package_type",(value)=>{
  // if(!value) return value;
  switch(value){
    case "1":
    return "pins.bv"
    break;

    case "0":
    return "pins.non_bv"
    break;
    default:
    return "pins.bv"
    break;
  }
})

Vue.filter("feeformat",(value)=>{
  if(!value) return value;
  return value.toFixed(1) + "%";
})



Vue.filter("qi_list",(value)=>{
  // if(!value) return value;
  switch(value){
    case 1:
    return "第一期"
    break;

    case 2:
    return "第二期"
    break;

    case 3:
    return "第三期"
    break;

    default:
    return "第一期"
    break;
  }
})

import {i18n} from "@/main"

Vue.filter("sex_type",(value)=>{
  if(!value) return value;
  switch(value){
    case 1:
    return i18n.t("info.man")
    break;

    case 2:
    return i18n.t("info.woman")
    break;

    case 3:
    return "info.keep_sevret"
    break;

    default:
    return "info.keep_sevret"
    break;
  }
})

 // 0为未申请认证，1为已认证, 2 拒绝认证（审核不通过）, 3 审核中
 Vue.filter("kyc_type",(value)=>{
  if(!value) return value;
  console.log(value)

  switch(value){
    case 0:
    return "mine.kyc0"
    break;

    case 1:
    return "mine.kyc1"
    break;

    case 2:
    return "mine.kyc2"
    break;

    case 3:
    return "mine.kyc3"
    break;

    default:
    return "mine.kyc3"
    break;
  }
})


Vue.filter("yinfour",(value,len)=>{
  if(!value) return value;
  len = len || 3;
  var num = value.substr(1, len);
  return value.replace(num,"***");
})


Vue.filter("cardformat",(value)=>{
  if(!value) return value;
  var reg = /\S{5}/;
  var v = value;
  if(reg.test(v)){
    value = v.replace(/\s/g, '').replace(/(.{4})/g, "$1 ");
  }
  return value
})



Vue.filter("time_type",(value)=>{
  if(!value) return value;
  switch(value){
    case 1:
    return 60
    break;

    case 3:
    return 30
    break;

    case 5:
    return 5
    break;

    default:
    return 60
    break;
  }
})
