<template>
  <van-nav-bar
    :title="title"
    fixed
    @click-left="onClickLeft"
    class="my-head"
    :style="{background:bg?'transparent':''}"
    :class="myclass"
  >
    <template #left v-if="show"  >
        <van-icon name="arrow-left"  v-if="!cancel" />
        <span class="head-cancel" v-if="cancel" >{{$t('login.cancel')}}</span>
    </template>
    <template #right  v-if="right">
        <slot></slot>
    </template>
  </van-nav-bar>
</template>

<script>
import {mapMutations} from "vuex"
export default {
   name:'myhead',
   props:{
     title:String,
     right:{
       type:Boolean,
     },
     show:Boolean,
     bg:Boolean,
     cancel:Boolean,
     yuan:Boolean,
     loginhead:Boolean
   },
   data(){
     return {
       
     }
   },
   computed:{
     myclass(){
      //  :class="bg?'null-head':''"
       if(this.bg){
          if(this.yuan){
            return "yuan-class null-head"
          }else{
            return "null-head"
          }
       }else{
         return ""
       }
     }
   },
   methods:{
     ...mapMutations(['setMoveClass','setgoForward']),
     onClickLeft(){
       console.log(this.$route.name)
        this.$router.go(-1);
        // this.setgoForward(false);
     }
   },
   
}
</script>