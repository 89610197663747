<template>
  <div id="app">
    <transition mode="out-in" :name="transitionName" >
      <keep-alive>
        <router-view class="bidbox" :key="$route.fullPath"></router-view>
        </keep-alive>
    </transition>
  </div>
</template>


<script>
import {mapState,mapMutations} from "vuex"
import Centrifuge from 'centrifuge';
import  SockJS from 'sockjs-client';
import {wsURL} from "@/api/config"
export default {
  data(){
    return {
        transitionName:"turn-on"
    }
  },
  methods:{
    connectSockJS(){
        var that = this;
        var centrifuge = new Centrifuge(wsURL, {
              sockjs: SockJS
        });
        centrifuge.setToken("eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2MDgxMDc0MTAsIm5iZiI6MTYwODEwNzQxMCwiZXhwIjoxOTE5MTQ3NDEwLCJzdWIiOiIyNTgifQ.TsYbgp-NNtjaqKy1ecqehPFtNyoIHz42qW_zs-e7-Bs");
        centrifuge.subscribe("app.#258", function(message) {
          
            if(message.data){
               var now_data = message.data;
              //  console.log(now_data);
               // console.log('buy_'+that.$route.query.type)
              if(now_data['buy_tt'] || now_data['buy_cb']){
                that.jiqigoodList.unshift(now_data)
                that.setjiqigoodList(that.jiqigoodList)
              }else{
                // tt_winninginfo cb_winninginfo 
                that.homewinnerList.push(now_data);
                that.sethomewinnerList(that.homewinnerList)
              }
            }
           
        });

        centrifuge.connect();

    },
    ...mapMutations(['setjiqigoodList','sethomewinnerList']),
  },
  computed:{
        ...mapState(['showNum','moveState','goForward','homewinnerList','jiqigoodList']),
        loginShow:{
          get(){
            return this.$store.state.loginShow
          },
          set(v){
            this.$store.commit('changeLoginShow',v)
          }
        }
    },
    watch:{
      '$route':function(to,from){
         
         localStorage.setItem("fromRoute",from.name)
         if (parseInt(to.query._t) < parseInt(from.query._t)) {
              this.transitionName = 'turn-off';
          } else {
              this.transitionName = 'turn-on';
          }
      }
    },
    mounted(){
        this.connectSockJS()
    }
}
</script>


<style lang="scss">
</style>
