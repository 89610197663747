let baseURL;
let wsURL
let origin
origin = window.location.origin;

// baseURL = 'http://onebid.test/';
// wsURL = "ws://103.84.85.82:8178/onebid/websocket";
if(origin.indexOf('test')!=-1){
    baseURL = 'https://onebid.club/';
    wsURL = 'wss://testwebsocket.onebid.club/onebid/websocket';
}else{
    baseURL = 'https://onebid.club/';
    wsURL = 'wss://testwebsocket.onebid.club/onebid/websocket';
    // baseURL = 'http://103.84.85.82:8077/';
    // wsURL = "ws://103.84.85.82:8178/onebid/websocket";
}

// if (process.env.VUE_APP_CURRENTMODE) {
//     switch (process.env.VUE_APP_CURRENTMODE) {
//         case 'dev': //测试开发环境打包需要修改的配置
//             baseURL = 'https://onebid.club';
//             wsURL = 'wss://testwebsocket.onebid.club/onebid/websocket';
//             break;
//         case 'production': //正式开发环境打包需要修改的配置
//             baseURL = 'https://onebid.club/';
//             wsURL = 'wss://testwebsocket.onebid.club/onebid/websocket';
//             break;
//         default:
//             baseURL = 'https://onebid.club/';
//             wsURL = 'wss://testwebsocket.onebid.club/onebid/websocket';
//     }
// } else {
//     switch (process.env.NODE_ENV) {
//         case 'development': //本地开发环境
//             baseURL = 'http://103.84.85.82:8077/';
//             wsURL = "ws://103.84.85.82:8178/onebid/websocket"
//             break;
//         default:
//             baseURL = 'http://103.84.85.82:8077/';
//             wsURL = "ws://103.84.85.82:8178/onebid/websocket"
//     }
// }

export { baseURL, wsURL};

// baseURL axios使用，后端请求接口地址的请求头
// testURL 配合下载使用，是各种excle下载的地址头部
// downLoadxls 一个模板excle下载的固定地址
// origin  配合window.open使用，是新开的页面的请求头
