import axios from 'axios';
import {Toast,Notify} from "vant"
import { getStore,setStore } from "@/common/utils.js";
import common from './baseurl';
import router from '../router';
import qs from 'qs';
import store from "@/store";
import loadGif from "@/assets/gif/loading.gif"

import {baseURL} from "./config"
// axios 配置
// axios.defaults.timeout = 20000;   //20s
// axios.defaults.baseURL = common.apihost
// axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

const ajax = axios.create({
    // baseURL:'',
    // baseURL:'http://103.84.85.82:8077/',
    baseURL,
    timeout:60000,
    headers: {
        'Access-Control-Allow-Origin':"*",
        'Access-Control-Expose-Headers':"Authorization,authenticated",
        'Access-Control-Allow-Headers':"Origin,Content-Type,Cookie,X-CSRF-TOKEN,Accept,Authorization,X-XSR",
        'Access-Control-Allow-Methods':'POST,GET,PUT,OPTIONS,PATCH'
        // "Content-Type": "application/json",
        // 'Content-Type': 'multipart/form-data'
        // 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    withCredentials: false,
})


// http request 拦截器
ajax.interceptors.request.use(

    config => {
        Toast.loading({
            forbidClick: true,
            loadingType:"spinner",
            duration:0,
            icon: loadGif,
            className:"load-toast"
        });
        const token = getStore('access_token');   // 获取token
        const lang = getStore("langData") || "cn";  // 默认中文
        config.headers['lang']=lang;
        config.headers.common['Authorization']='Bearer ' + token;


        // if(config.method === 'post') {
        //     // 文件上传的特殊判断
        //     if(config.data){
        //         if(config.data.formData){    // 文件上传的特殊处理
        //             let formData = config.data.formData;
        //             config.data = formData;
        //             console.log(config);
        //         }else{
        //             config.data = {...config.data}
        //             config.data = qs.stringify(config.data);
        //         }
        //     }
        // }
    return config
  },
  err => {
    Toast.clear();
    Toast.fail('请求失败');
    return Promise.reject(err)
  },
)

// 响应拦截（配置请求回来的信息）
ajax.interceptors.response.use(function (response){


    // console.log(response.data);

    // 判断是否需要 msg
    // console.log(store.state.noMsg)
    Toast.clear();
    Notify.clear();
    if(response.data.code == "200" && !store.state.noMsg){
        // Notify({ type: 'success', message: response.data.msg ,duration:1200});

        // Toast(response.data.msg)
    }else if(response.data.code == '402' || response.data.code == '401' || response.data.code == '403' ){
        // Notify
        Notify({
            message: response.data.msg,
            color: '#ad0000',
            background: '#ffe1e1',
        });
        // Toast.fail(response.data.msg)
    }else if(response.data.code == '405' ){
        // 登录过期  回到405
        // Toast(response.data.msg)
        if(localStorage.getItem('access_token')){
            Notify({
                message: response.data.msg,
                color: '#ad0000',
                background: '#ffe1e1',
            });
        }
        store.commit("setdashboard",null);
        store.commit("setCardList",null);
        localStorage.removeItem("access_token");
        localStorage.removeItem('dashboard');
        // router.push({path:"/signin",query:{_t:new Date().getTime().toString()}});
        // router.push({path:"/signin"});
        router.push({name:'signin'})

    }else{
    }

    store.commit("setnoMsg",false);   // 需要提示 msg
 　　return response;
}, function (error){

    let originalRequest = error.config;

    Toast.clear();
    Toast.fail('响应失败');
    return Promise.reject(error);
 });

export  {ajax}
/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params){
    return new Promise((resolve, reject) =>{
        // common.apihost+
        ajax.get(url, {
            params: params
        })
        .then(res => {
            resolve(res.data);
        })
        .catch(err => {
            reject(err.data)
        })
    })
}
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params,headers) {
    return new Promise((resolve, reject) => {
        // common.apihost+ 暂时取消 使用代理
        // params = qs.stringify(params);
        ajax.post(url, params,headers)
        .then(res => {
            resolve(res.data);
        })
        .catch(err => {
            reject(err.data)
        })
    })
}
